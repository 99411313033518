
.location-graffiti {
  background-image: url("/img/location/location-graffiti.jpg");
}
.location-coffee {
  background-image: url("/img/location/location-coffee.jpg");
}
.location-golf {
  background-image: url("/img/location/location-golf.jpg");
}
.location-pasta {
  background-image: url("/img/location/pasta.jpg");
}
.location-music {
  background-image: url("/img/location/location-music.jpg");
}
.location-side {
    background-image: url("/img/location/Location-side.png");
}
.sunbury-living-one {
    background-image: url("/img/location/Sunbury-living-one.png");
}

.sunbury-living-two {
    background-image: url("/img/location/Sunbury-living-two.png");
}

.sunbury-side-one {
    background-image: url("/img/location/Sunbury-side-one.png");
}
.sunbury-side-two {
    background-image: url("/img/location/Sunbury-side-two.png");
}