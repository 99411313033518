.moremac-the-point {
  background-image: url("/img/teams/moremac-the-point.jpg");
}
.moremac-image {
  background-image: url("/img/teams/moremac-image.jpg");
}
.aus-land-corp {
  background-image: url("/img/teams/alc.jpg");
}

.team-logo-wrapper {
  svg {
    max-width: 100%;
  }
}