.bg-hero {
    background-image: url("/img/home/hero.jpg");
}

.bg-better_future {
    background-image: url("/img/home/better_future-pattern.png");
}
.bg-saltbush-aerial {
  background-image: url("/img/home/saltbush-aerial.jpg");

}
.bg-better_future_pic {
    background-image: url("/img/home/better-future.jpg");
}

.bg-park-aerial {
  background-image: url("/img/home/park.jpg");
}