@import 'for';
@import 'variables';

//? Colours and backgrounds
.for(@colours); .-each(@value) {
  @name:  extract(@value, 1);
  @colour: extract(@value, 2);
  .col-@{name} {
      color: @colour;
  }
  .hover-@{name} {
    &:hover {
      color: @colour;
    }
  }
  .bg-@{name} {
    background-color: @colour;
  }
  .bghover-@{name} {
    &:hover {
      background-color: @colour;
    }
  }
  .border-@{name} {
    border-color: @colour;
  }
  .border-hover-@{name} {
    &:hover{
      border-color: @colour;
    }
  }


  .svg-@{name}{
    path{
      fill: @colour;
    }
  }

  .svg-stroke-@{name}{
    path{
      stroke: @colour;
    }
  }

  .svg-hover-@{name}{
    path{
      transition: @transition;
    }
    &:hover{
      path{
        fill: @colour;
      }
    }
  }

  .svg-hover-stroke-@{name}{
    path{
      transition: @transition;
    }
    &:hover{
      path{
        stroke: @colour;
      }
    }
  }

  @media (max-width: 991px) {
    .col-@{name}__mob {
      color: @colour;
    }
    .hover-@{name}__mob {
      &:hover {
        color: @colour;
      }
    }
    .bg-@{name}__mob {
      background-color: @colour;
    }
    .bghover-@{name}__mob {
      &:hover {
        background-color: @colour;
      }
    }
    .border-@{name}__mob {
      border-color: @colour !important;
    }
    .border-hover-@{name}__mob {
      &:hover{
        border-color: @colour !important;
      }
    }

    .svg-@{name}__mob{
      path{
        fill: @colour;
      }
    }

    .svg-stroke-@{name}__mob{
      path{
        stroke: @colour;
      }
    }

    .svg-hover-@{name}__mob{
      path{
        transition: @transition;
      }
      &:hover{
        path{
          fill: @colour;
        }
      }
    }
  
    .svg-hover-stroke-@{name}__mob{
      path{
        transition: @transition;
      }
      &:hover{
        path{
          stroke: @colour;
        }
      }
    }

  }
};

.for(@sizes); .-each(@value) {
  .mh-@{value}-vh {
    min-height: ~"@{value}vh";
  }
  .mxh-@{value}-vh {
    max-height: ~"@{value}vh";
  }
  .h-@{value}-vh {
    height: ~"@{value}vh";
  }
  .mh-@{value}-px {
    min-height: ~"@{value}px";
  }
  .mxh-@{value}-px {
    max-height: ~"@{value}px";
  }
  .h-@{value}-px {
    height: ~"@{value}px";
  }
  .h-@{value}-p {
    height: ~"@{value}%";
  }
  .mh-@{value}-p {
    min-height:  ~"@{value}%";
  }
  .mw-@{value}-vw {
    min-width: ~"@{value}vw";
  }
  .w-@{value}-vw {
    width: ~"@{value}vw";
  }
  .mw-@{value}-px {
    min-width: ~"@{value}px";
  }
  .mxw-@{value}-px {
    max-width: ~"@{value}px";
  }
  .w-@{value}-px {
    width: ~"@{value}px";
  }
  .mw-@{value}-p {
    min-width:  ~"@{value}%";
  }
  .w-@{value}-p {
    width:  ~"@{value}%";
  }
  .z-@{value} {
    z-index: @value;
  }
  .font-@{value} {
    font-size: ~"@{value}px";
  }
  .ls-@{value} {
    letter-spacing: ~"@{value}px";
  }
  .lh-@{value} {
    line-height: ~"@{value}px";
  }
  .gap-@{value} {
    gap: ~"@{value}px";
  }
  .border-@{value} {
    border-style: solid;
    border-width: ~"@{value}px";
  }
  .bl-@{value} {
    border-left-style: solid;
    border-left-width: ~"@{value}px";
  }
  .bt-@{value} {
    border-top-style: solid;
    border-top-width: ~"@{value}px";
  }
  .bb-@{value} {
    border-bottom-style: solid;
    border-bottom-width: ~"@{value}px";
  }
  .br-@{value} {
    border-right-style: solid;
    border-right-width: ~"@{value}px";
  }



  .svg-h-@{value}-px {
    svg{
      height: ~"@{value}px";
    }
  }
  .svg-w-@{value}-px {
    svg{
      width: ~"@{value}px";
    }
  }

  @media (max-width: 991px) {
    .mh-@{value}-px__mob {
      min-height: ~"@{value}px !important";
    }
    .mxh-@{value}-px__mob {
      max-height: ~"@{value}px !important";
    }
    .h-@{value}-px__mob {
      height: ~"@{value}px !important";
    }
    .mh-@{value}-vh__mob {
      min-height: ~"@{value}vh !important";
    }
    .h-@{value}-vh__mob {
      height: ~"@{value}vh !important";
    }
    .h-@{value}-p__mob {
      height: ~"@{value}% !important";
    }
    .mh-@{value}-p__mob {
      min-height:  ~"@{value}% !important";
    }
    .mw-@{value}-vw__mob {
      min-width: ~"@{value}vw !important";
    }
    .mxw-@{value}-vw__mob {
      max-width: ~"@{value}vw !important";
    }
    .w-@{value}-vw__mob {
      width: ~"@{value}vw !important";
    }
    .mw-@{value}-p__mob {
      min-width:  ~"@{value}% !important";
    }
    .w-@{value}-p__mob {
      width:  ~"@{value}% !important";
    }
    .mw-@{value}-px__mob {
      min-width: ~"@{value}px !important";
    }
    .mxw-@{value}-px__mob {
      max-width: ~"@{value}px !important";
    }
    .w-@{value}-px__mob {
      width: ~"@{value}px !important";
    }
    .font-@{value}__mob {
      font-size: ~"@{value}px !important";
    }
    .ls-@{value}__mob {
      letter-spacing: ~"@{value}px !important";
    }
    .lh-@{value}__mob {
      line-height: ~"@{value}px !important";
    }
    .gap-@{value}__mob {
      gap: ~"@{value}px !important";
    }
    .border-@{value}__mob {
      border-style: solid !important;
      border-width: ~"@{value}px !important";
    }
    .bl-@{value}__mob {
      border-left-style: solid !important;
      border-left-width: ~"@{value}px !important";
    }
    .bt-@{value}__mob {
      border-top-style: solid !important;
      border-top-width: ~"@{value}px !important";
    }
    .bb-@{value}__mob {
      border-bottom-style: solid !important;
      border-bottom-width: ~"@{value}px !important";
    }
    .br-@{value}__mob {
      border-right-style: solid !important;
      border-right-width: ~"@{value}px !important";
    }


    .svg-h-@{value}-px__mob {
      svg{
        height: ~"@{value}px" !important;
      }
    }
    .svg-w-@{value}-px__mob {
      svg{
        width: ~"@{value}px" !important;
      }
    }
    
  }
};

.svg-w-auto {
  svg{
    width: auto;
  }
}
.svg-h-auto {
  svg{
    height: auto;
  }
}


//? background
.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-tl {
  background-position: top left;
}
.bg-tc {
  background-position: top center;
}
.bg-tr {
  background-position: top right;
}

.bg-cl {
  background-position: center left;
}
.bg-cc {
  background-position: center center;
}
.bg-cr {
  background-position: center right;
}

.bg-bl {
  background-position: bottom left;
}
.bg-bc {
  background-position: bottom center;
}
.bg-br {
  background-position: bottom right;
}

.tr-1s-ease-all {
  transition: 1s ease all;
}
.transition-default {
  transition: @transition;
}

.postion-relative {
  position: relative;
}

.line-height-0-9 {
  line-height: 0.9;
}

.ls-1 {
  letter-spacing: 1px;
}

.text-underline {
  text-decoration: underline;
}

.cols-2 {
  column-count:2
}

.image-fit{
  object-fit: cover;
  object-position: center;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .col-1__mob {
    column-count:1;
  }
  .bg-contain__mob {
    background-size: contain;
  }

  .mh-auto__mob {
    min-height: auto !important;
  }
  .b-none__mob {
    border: none;
  }

  .mxw-unset__mob{
    max-width: unset !important;
  }

  .svg-w-auto__mob {
    svg{
      width: auto !important;
    }
  }
  .svg-h-auto__mob {
    svg{
      height: auto !important;
    }
  }

}

// xs styles
@media (max-width: 767px) {

}