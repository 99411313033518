.bg-living {
  background-image: url('/img/living/hero.jpg');

}

.better-for-planet {
    background-image: url("/img/living/Better-for-planet.png");
    background-repeat: no-repeat;
    @media (max-width: 991px) {
        background-image: url("/img/living/Better-for-planet__mob.png");
    }
}

.hl-hero {
    background-image: url("/img/living/Home-life-hero.png");
    @media (max-width: 991px) {
        background-image: url("/img/living/Home-life-hero__mob.png");
    }
}
.bg-street-corner {
  background-image: url("/img/living/street-corner.jpg");
}
.hl-side {
    background-image: url("/img/living/Home-life-side.png");
    @media (max-width: 991px) {
        background-image: url("/img/living/Home-life-side__mob.png");
    }
}
.bg-waterside {
  background-image: url("/img/living/waterside.jpg");
  @media (max-width: 991px) {
      // background-image: url("/img/living/Home-life-side__mob.png");
  }
}

.living-standards {
    background-image: url("/img/living/Living-standards.png");
    @media (max-width: 991px) {
        background-image: url("/img/living/Living-standards__mob.png");
    }
}
.hl-decor {
    background-image: url("/img/living/Home-life-decor.png");
}
.hl-bed {
  background-image: url("/img/living/bed.jpg");
}
.hl-living-townhome {
  background-image: url("/img/living/townhome.jpg");
}

.bg-living-townhomes {
  background-image: url("/img/living/townhomes.jpg");
}

.interior-hero {
    background-image: url("/img/living/Interior-spaces-top.png");
}
.interior-side {
    background-image: url("/img/living/Interior-spaces-side.png");
}