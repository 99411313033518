@labelCol: #fff;

@inputCol: black;
@backgroundCol: transparent;

@formFontSize: 20px;

@submitCol: #f04152;
@submitBg: #fff;

@borderCol: @inputCol;

.border {
  margin-top: 10px;
}
.form-holder {
  width: 350px;
  float: left;
}
.error {
  color: red !important;
}
.form-title {
  width: 100%;
  color: @labelCol;
  height: 33px;
  padding: 7px 4px 0px 6px;
  margin-bottom: 2px;
}
.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  border-radius: 0px;
  appearance: none;
  height: 35px;
  width: 100%;
  margin-bottom: 20px;
  border: 0;
  border-bottom: 1px solid black;
  font-size: @formFontSize;
  font-family: @fontAlpina;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.05em;
  background-color: @backgroundCol;
  color: @inputCol;
  outline: none;
  @media (max-width: 991px) {
    font-size: 14px !important;
  }
}

.custom-textarea {
  height: 100px;
}

.custom-select {
  padding-top: 4px;
  // height: 38px;
  box-sizing: border-box;
  background: url("../img/core/arrow.png") 97% 50% no-repeat @backgroundCol;
  padding-left: 0;
  @media (max-width: 991px) {
    padding: 0!important;
    margin-bottom: 5px !important;
    width: 100%;
  }
}

.custom-submit {
  color: @inputCol;
  box-shadow: none;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  transition: 0.5s ease;
  text-decoration: underline !important;
  &:hover {
    background-color: #28baaf;
  }
}

.submit-button {
  border: 0 !important;
  padding: 7px 30px;
}

.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container h4 {
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
label {
  color: @labelCol;
  font-weight: bold;
}
::placeholder {
  color: #767676;
}
option {
  color: #000000 !important;
}

.customSelectInner {
  width: 100% !important;
}

.form-field-left-half,
.form-field-right-half,
.form-field-full {
  float: left;
  width: 50%;
  height: 50px;
}
.form-field-right-half {
  float: right;
}
.form-field-full {
  width: 100%;
}
.form-field {
  clear: both;
}

.main-inuput-wrapper {
  display: flex !important;

  .left-wrapper,
  .right-wrapper {
    width: 50%;
  }
  .left-wrapper {
    padding-right: 20px;
  }
  .right-wrapper {
    padding-left: 20px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    font-size: 14px !important;

    .left-wrapper,
    .right-wrapper {
      width: 100%;
      padding: 0;
    }
  }
}

// md styles
@media (max-width: 1199px) {
}

// sm styles
@media (max-width: 991px) {
}

// xs styles
@media (max-width: 767px) {
}
