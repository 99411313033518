.hero-community {
  background-image: url("/img/community/hero.jpg");
}
.bg-community {
  background-image: url("/img/community/Community_pattern.png");
}

.join-community {
    background-image: url("/img/community/Join-community.png");
    background-repeat: no-repeat;
}

.bg-runners {
  background-image: url("/img/community/runners.jpg");
}

.something-special {
    background-image: url("/img/community/Something-special.png");
}

.great-community {
    background-image: url("/img/community/Great-community.png");
    background-repeat: no-repeat;
}


.towncentre {
  background-image: url("/img/community/Towncentre_kingsfield.png");
}

.bg-accordian-brown {
  background-image: url("/img/home/accordian/brown.png");
}
.bg-accordian-light {
  background-image: url("/img/home/accordian/light.png");
}

.bg-accordian-towncentre {
  background-image: url("/img/home/accordian/img-towncenter.jpg");
}
.bg-accordian-intersection {
  background-image: url("/img/home/accordian/img-intersection.jpg");
}
.bg-accordian-location {
  background-image: url("/img/home/accordian/img-location.jpg");
}
.bg-accordian-team {
  background-image: url("/img/home/accordian/img-team.jpg");
}
.bg-accordian-aerial {
  background-image: url("/img/home/accordian/img-aerial.jpg");
}
.bg-accordian-living {
  background-image: url("/img/home/accordian/img-living.jpg");
}