@font-face {
    font-family: "Alpina Italic";
    src: url("./../fonts/GT-Alpina-Standard-Light-Italic.woff2") format("woff2"),
        url("./../fonts/GT-Alpina-Standard-Light-Italic.woff") format("woff"),
        url('./../fonts/GT-Alpina-Standard-Light-Italic.eot') format('eot'),
        url("./../fonts/GT-Alpina-Standard-Light-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Alpina";
    src: url("./../fonts/GT-Alpina-Standard-Light.woff2") format("woff2"),
        url("./../fonts/GT-Alpina-Standard-Light.woff") format("woff"),
        url("./../fonts/GT-Alpina-Standard-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Favorit";
    src: url("./../fonts/ABCFavorit-Regular.woff2") format("woff2"),
        url("./../fonts/ABCFavorit-Regular.woff") format("woff"),
        url("./../fonts/ABCFavorit-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Favorit";
    src: url("./../fonts/ABCFavorit-Medium.woff2") format("woff2"),
        url("./../fonts/ABCFavorit-Medium.woff") format("woff"),
        url("./../fonts/ABCFavorit-Medium.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Halyard Black";
    src: url("./../fonts/HalyardText_Black.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
  font-family: 'Balto-Book';
  src: url('./../fonts/Balto-Book.eot');
  src: url('./../fonts/Balto-Book.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/Balto-Book.woff2') format('woff2'),
      url('./../fonts/Balto-Book.woff') format('woff'),
      url('./../fonts/Balto-Book.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Balto-Medium';
  src: url('./../fonts/Balto-Medium.eot');
  src: url('./../fonts/Balto-Medium.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/Balto-Medium.woff2') format('woff2'),
      url('./../fonts/Balto-Medium.woff') format('woff'),
      url('./../fonts/Balto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Balto-Light';
  src: url('./../fonts/Balto-Light.eot');
  src: url('./../fonts/Balto-Light.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/Balto-Light.woff2') format('woff2'),
      url('./../fonts/Balto-Light.woff') format('woff'),
      url('./../fonts/Balto-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Balto-Thin';
  src: url('./../fonts/balto-thin.eot');
  src: url('./../fonts/balto-thin.eot?#iefix') format('embedded-opentype'),
      url('./../fonts/balto-thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

.balto-book {
  font-family: "Balto-Book", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.balto-md {
  font-family: "Balto-Medium", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.balto-light {
  font-family: "Balto-Light", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.balto-thin {
  font-family: "Balto-Thin", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.sans {
    font-family: "Favorit", sans-serif;
    font-weight: 400;
}
.sans-bold {
    font-family: "Favorit", sans-serif;
    font-weight: 600;
}
.halyard-black {
    font-family: "Halyard Black";
    font-weight: 600;
}

.serif {
    font-family: "Alpina", serif;
}
.italic {
    font-family: "Alpina Italic", italic;
    font-style: italic;
}


@fontxxl: 72px;
@fontxl : 48px;
@fontlg : 36px;
@fontmd : 24px;
@fontsm : 18px;
@fontxs : 16px;
@fontxxs: 12px;


@fontSup: 9px;
@font6  : 6px;


.single-mod-headline-size {
    font-size: 5vh;
}
.slider-headline-size {
    font-size: 2vh;
}
// Responsive sizes in vh

.font-xxl {
    font-size: @fontxxl;
}
.font-xl {
    font-size: @fontxl;
}
.font-lg {
    font-size: @fontlg;
    line-height: 1;
}
.font-md {
    font-size: @fontmd;
}
.font-sm {
    font-size: @fontsm;
}
.font-xs {
    font-size: @fontxs;
}
.font-xxs {
    font-size: @fontxxs;
}

.font-sup {
    font-size: @fontSup;
}

// md styles
@media (max-width: 1199px) {
}

// sm styles
@media (max-width: 991px) {

    // @fontxxl: 72px;
    // @fontxl : 48px;
    // @fontlg : 36px;
    // @fontmd : 24px;
    // @fontsm : 18px;
    // @fontxs : 16px;
    // @fontxxs: 12px;


    @fontxl: 36px;
    @fontlg: 30px;
    @font26: 26px;
    @font24: 24px;
    @font20: 20px;
    @fontmd: 18px;
    @fontsm: 14px;
    @fontxs: 12px;
    @fontSup: 4px;

    .font-xl__mob {
        font-size: @fontxl;
    }
    .font-lg__mob {
        font-size: @fontlg;
        line-height: 1;
    }
    .font-md__mob {
        font-size: @fontmd;
    }
    .font-20__mob {
        font-size: @font20;
    }
    .font-sm__mob {
        font-size: @fontsm;
    }
    .font-xs__mob {
        font-size: @fontxs;
    }
    .font-26__mob {
        font-size: @font26;
    }
    .font-24__mob {
        font-size: @font24;
    }
    .font-6__mob {
        font-size: @font6;
    }
    .font-sup__mob {
        font-size: @fontSup;
    }
}

// xs styles
@media (max-width: 767px) {
}
