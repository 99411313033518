.bg-playground {
  background-image: url("/img/amenity/playground.jpg");
}
.bg-emu-creek {
  background-image: url("/img/amenity/emu-creek.jpg");
}
.bg-town-center {
  background-image: url("/img/amenity/town-center.jpg");
}
.bg-town-center2 {
  background-image: url("/img/amenity/town-center2.jpg");
}
.bg-creek-closeup {
  background-image: url("/img/amenity/creek-closeup.jpg");
}


.bg-live-amongst {
  background-image: url("/img/amenity/Live-amongst.png");
}
.bg-live-amongst-land {
  background-image: url("/img/amenity/Live-amongst-land.png");
}

// Amenitities section
.bg-amenities-drone {
  background-image: url("/img/amenity/Amenities-drone.png");
}
.bg-amenities-towncentre {
  background-image: url("/img/amenity/Amenities-towncentre.png");
}

.bg-biodiversity {
  background-image: url("/img/amenity/bg-pattern-green.png");
}
.bg-biodiversity-lake {
  background-image: url("/img/amenity/Biodiversity-lake.png");
  background-repeat: no-repeat;
}

.bg-conservation-mob {
  background-image: url("/img/amenity/conservation.jpg");
}

.hero-tomorrow-like {
    background-image: url("/img/amenity/Tomorrow-like.png");
}

// Explore Buying Options
.ebo-land {
    background-image: url("/img/amenity/EBO-Land.png");
}
.ebo-house-land {
    background-image: url("/img/amenity/EBO-House-Land.png");
}
.ebo-townhomes {
    background-image: url("/img/amenity/EBO-Townhomes.png");
}

.everything {
  @media (max-width: 991px) {
    .img {
      height: 250px;
    }
  }
}

.amongst {
  @media (max-width: 991px) {
    .img {
      height: 269px;
    }
  }
}

.amenities {
  .bg-town-center,
  .hl-hero,
  .interior-hero,
  .bg-street-corner,
  .bg-living-townhomes,
  .location-golf {
    height: 912px;
  }
  .bg-town-center2,
  .hl-side,
  .interior-side {
    height: 689px;
  }
  @media (max-width: 991px) {
    .bg-town-center,
    .hl-hero,
    .interior-hero,
    .bg-street-corner,
    .bg-living-townhomes,
    .location-golf {
      height: 245px;
    }
    .bg-town-center2,
    .hl-side,
    .interior-side,
    .bg-street-corner {
      height: 208px;
    }
  }
}

.everything,
.amongst,
.amenities,
.conservation {
  @media (max-width: 991px) {
    p {
      br {
        display: none;
      }
    }
  }
}

.icon {
  @media (max-width: 991px) {
    svg {
      width: 49px;
      // height: 40px;
    }
  }
}

.conservation {
  .bg-creek-closeup {
    @media (max-width: 991px) {
      height: 245px;
    }
  }
}
