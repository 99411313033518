.menu-horizontal {
	list-style: none;
	padding: 0;
	margin: 10px 0;
	text-align: center;
	li {
		display: inline-block;
		list-style: none;
    	padding: 0;
		margin-right: 10px;
		text-transform: uppercase;
		a {
			transition: 0.5s ease;
			text-decoration: none;
			&:hover {
				transition: 0.5s ease;
		 		text-decoration: underline;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}

