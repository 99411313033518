.bg-hero {
    background-image: url('/img/home/hero.jpg');
}
.bg-pattern {
    background-image: url('/img/home/pattern.png');
}
.bg-pattern2 {
    background-image: url('/img/home/pattern2.png');
}
.bg-lake {
    background-image: url('/img/home/lake.jpg');
  }
.bg-lots {
    background-image: url('/img/home/lots.jpg');
}
.bg-townhouse {
    background-image: url('/img/home/townhouse.jpg');
}
.bg-amenity {
    background-image: url('/img/amenity/hero.jpg');
}
.bg-community {
  background-image: url('/img/community/hero.jpg');
}

.bg-sustainability {
    background-image: url('/img/sustainability/Sustainability-hero.png');
}


.bg-location {
    background-image: url("/img/location/Location-hero.png");
}

.bg-teams {
    background-image: url("/img/teams/Teams-hero.png");
}

.bg-aerial {
  background-image: url("/img/core/aerial.jpg");
}


.hero-location {
  background-image: url("/img/location/hero.jpg");
}

.hero-teams {
  background-image: url("/img/teams/hero.jpg");
}
