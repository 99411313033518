@import (less) "normalize.css";

@import (less) "bootstrap-custom";

@import (less) "../js/vendor/royalslider/royalslider.css";
@import (less) "../js/vendor/royalslider/skins/default/rs-default.css";
@import (less) "../js/vendor/slick/slick.less";

@import (less) "../recaptcha/recaptcha.css";

@import (less) "savi-core.less";

@import (less) "variables.less";
@import (less) "fonts.less";
@import (less) "helpers.less";
@import (less) "menu-horizontal.less";
@import (less) "slider.less";
@import (less) "form.less";
@import (less) "thankyou.less";

@import (less) "hero.less";
@import (less) "title.less";

@import (less) "buying-options.less";
@import (less) "neighbourhoods.less";

@import (less) "home.less";
@import (less) "community.less";
@import (less) "amenity.less";
@import (less) "community.less";
@import (less) "sustainability.less";
@import (less) "living.less";
@import (less) "location.less";
@import (less) "teams.less";
@import (less) "masterplan.less";
@import (less) "map.less";

@import (less) "mapovis.less";
@import (less) "register.less";

.video-bg{
  position: relative;
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.kingsfield-ml {
    margin-left: 30px;
    @media (min-width: 992px) {
        margin-left: 100px;
    }
}
.kingsfield-pt {
    padding-top: 56px;
}

.kingsfield-pl {
    padding-left: 30px;
    @media (min-width: 992px) {
        padding-left: 100px;
    }
}

.underline-animation {
  position: relative;
  padding: 0.2em 0;
  display: inline;
  transition: opacity 300ms, transform 300ms;
  &:hover,
  &:focus {
    &:after {
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: hotpink;
    opacity: 0;

    &:hover {
      opacity: 1;
      transform: scale(0);
      transform-origin: center;
      transform: scale(1);
    }
  }
}



.arrow__left {
    background-image: url("/img/sliders/arrows/Polygon_left.png");
}
.arrow__right {
    background-image: url("/img/sliders/arrows/Polygon_right.png");
}

// Neighbourhoods patterns
.northpoint__pattern {
    background-image: url("/img/sliders/Neightbours-slider/Northpoint_pattern.png");
}
.panorama__pattern {
    background-image: url("/img/sliders/Neightbours-slider/Panorama_pattern.png");
}
.saltbush__pattern {
    background-image: url("/img/sliders/Neightbours-slider/Saltbush_pattern.png");
}
.banksia__pattern {
    background-image: url("/img/sliders/Neightbours-slider/Banksia_pattern.png");
}
.master__logo {
    background-image: url("/img/sliders/Neightbours-slider/MasterLogo.png");
    background-repeat: no-repeat;
}




// Brochure Statement
.header-girl {
    background-image: url("/img/home/girl.jpg");
}

// Footer pattern
.footer-pattern {
    background-image: url("/img/home/Footer_pattern.png");
    @media (max-width: 991px) {
        background-image: url("/img/home/Footer_pattern_mob.png");
    }
}
// .footer-bg {
//     background-image: url("/img/home/Footer_bg-color.png");
// }
.opacity-25 {
    background-color: @green__100;
    opacity: 0.2;
}

.menu {
    transition: all 0.5s ease;
    span {
        transition: all 0.5s ease;
    }
    &:hover {
        transition: all 0.5s ease;
        span {
            border-bottom: 2px solid @grey;
            transition: all 0.5s ease;
        }
    }
}
.w-menu {
  width: 420px;
}

.border-button {
    background-color: @yellow;
    text-transform: uppercase;
    text-decoration: none;
    width: fit-content;
    padding-top: 15px;
    padding-bottom: 15px;
    .sans;
    // .font-xs;
    .ls-1;
    transition: all 0.5s ease;

    span {
        color: @grey;
        transition: all 0.5s ease;
        // border-bottom: 1px solid @grey;
    }

    // &.top-bar-btn {
    //     background-color: @green__100;
    // }

    &:hover {
        background-color: @green__100;

        text-decoration: none;
        transition: all 0.5s ease;

        span {
            // color: @yellow;
            transition: all 0.5s ease;
            // border-bottom: 1px solid @yellow;
        }
    }

    @media (max-width: 991px) {
        .font-xxs;
        span {
            .font-xxs;
        }
    }
}

.border-button[type=submit] {
  padding: 10px 30px !important;
}
.border-botton__brochure {
  .border-button;
  &:hover {
    background-color:#ffffff;
  }
}

// Rego Form
.reg-form {
    @media (max-width: 991px) {
        .border-button {
            // padding: 20px 30px !important;
            width: 155px !important;
        }
        .custom-submit {
            height: 40px !important;
        }
    }
}

.transparent-border-button {
    color: @black;
    text-transform: uppercase;

    span {
        border-bottom: 1px solid @grey;
    }

    &:hover {
        color: @black;
    }

    @media (max-width: 991px) {
        .no-border {
            .font-xxs;
        }
    }
}

.explore-buying-options {
    @media (max-width: 991px) {
        flex-direction: column;
        padding: 1rem !important;
    }
}

.better-future-home {
    @media (max-width: 991px) {
        flex-direction: column-reverse;
    }
}

// 4 Neighbourhood slider
.slide {
    width: 85% !important;
    @media (max-width: 991px) {
        .master__logo {
            margin-top: 45px !important;
            margin-bottom: 0 !important;
        }
        h4:nth-of-type(2) {
            margin-top: 100px !important;
        }
    }
}

.neighbours-slider {
    @media (max-width: 991px) {
        padding-top: 50px !important;
    }
}

// Community
.bg-community {
    .towncentre {
        background-repeat: no-repeat !important;
    }
}

// Contact Details
.footer-pattern {
    @media (max-width: 991px) {
        // height: 226px !important;
    }
    .footer-bg {
        @media (max-width: 991px) {
            // height: 226px !important;
            padding: 28px 27px !important;
            .top-wrapper,
            .bottom-wrapper {
                flex-direction: column !important;
                margin: 0 !important;
                div {
                    margin: 0 0 15px 0 !important;
                }

                .contact-social {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 0 !important;
                    span:first-of-type {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

// FOOTER
.footer {
    @media (max-width: 991px) {
        padding-left: 27px !important;
        div {
            margin-bottom: 2px !important;
            padding: 0 !important;
        }

        .trademark {
            p {
                margin-bottom: 2px !important;
            }
        }
    }
}
.logo-wrapper {
  width: 500px;
  @media (max-width: 991px) {
    width: 250px;
  }
}

.builder-event .menu-container .rhs,
.step-two-builder .menu-container .rhs,
.builder-event .kebab-menu,
.step-two-builder .kebab-menu
 {
  display: none !important;
}

// MENU STYLING
.menu {
    font-size: 2.5vh;
    a {
        transition: all 0.2s;
    }
}

// Kebab menu
.kebab-menu {
    cursor: pointer;
}


.menu-collapse {
  height: 0vh;
  // box-shadow: -10px 0px 10px 1px  rgba(0,0,0,0.2);
  box-shadow: -40px 4px 20px -28px rgba(0, 0,0, 0.2);
  &.menu-display {
      min-height: 90vh;
      transition: all 0.5s ease;
      z-index: 500;
      // border-left: 1px solid @white;
      @media (max-width: 991px) {
          height: 100vh !important;
      }
      @media (min-width: 992px) {
        position: absolute;
        top: 10vh;
      }
  }
}

.submenu-collapse {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;
  &.submenu-display {
      max-height: 100% !important;
      transition: all 0.5s ease;
  }
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.2s;
}

/* Rotate first bar */
.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

/* Fade out the second bar */
.change .bar2 {
    opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}
// Kebab menu Finish

.top-bar {
    // @media (max-width: 991px) {
    //     background-color: rgba(255, 255, 255, 0.9) !important;
    // }
}

.slider {
    height: 40vh;
    .slide {
        height: 40vh;
    }
}
.slider-controll {
  width: 30px;
}

@media (max-width: 991px) {
    .neighbours-slider {
        padding: 0 0 0 15px;
    }
    .slider {
        .slide {
            margin: 0 45px 0 15px !important;
        }
    }
    .kingsfield-ml {
        margin: 0 !important;
    }

    // Community
    .bg-community {
        // height: 85vh !important;
        padding: 0 20px 50px !important;
        .mobile {
            width: 90% !important;
            display: flex;
            flex-direction: column-reverse;
            div {
                justify-content: flex-start !important;
                align-items: flex-start !important;
            }
        }
        .towncentre {
            margin-left: 0 !important;
            padding: 0 !important;
            width: 100% !important;
            height: 25vh !important;
        }
        h3 {
            margin: 45px 0 45px !important;
            padding: 0 !important;
        }
        p {
            margin: 30px 0 0 !important;
        }
    }

    .mobile-hidden {
        display: none !important;
    }

    // Rego Form
    .rego-form__mob {
        // padding: 4rem 2.5rem !important;

        p {
            br {
                display: none;
            }
        }
    }
}

.stories-arrows-desktop{
    display: flex;

    // for extra large screens
    @media (min-width: 1837px) {
        display: none;
    }

    // for tablets
    @media (max-width: 991px) {
        display: none;
    }
}