.stage-row {
  background-color: @green__100;
}
.stage-row__alternate {
  background-color: @light_grey;
}
.update-stage {
  height: 4px;
}
.progress-indicator {
  width: 12%;
  margin-right: 0.5%;
  height: 4px;
  float: left;
  background-color: @green__100;
}
.progress-indicator__active {
  .progress-indicator;
  background-color: @dark__green;
}