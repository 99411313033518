.brochure-bg {
  aspect-ratio: 1/1.4;
}
.bg-saltbush {
  background-image: url("/img/neighbourhoods/saltbush.jpg");
}
.bg-acacia {
  background-image: url("/img/neighbourhoods/acacia.jpg");
}
.bg-creekside {
  background-image: url("/img/neighbourhoods/creekside.jpg");
}

