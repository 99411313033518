.hero-sustainability {
  background-image: url("/img/sustainability/hero.jpg");
}
.bg-smart-water {
  background-image: url("/img/sustainability/smart-water.jpg");
}
.bg-roads {
  background-image: url("/img/sustainability/roads.jpg");
}
.bg-sustainability {
  background-image: url("/img/sustainability/bg-sustainability.jpeg");
}
.better-future {
  background-image: url("/img/home/better-future.jpg");
}
.bg-solar {
  background-image: url("/img/sustainability/solar.jpg");
}